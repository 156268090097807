import React from "react";

import Navigation from "./navigation";
import Footer from "./footer";

import "./styles.scss";

const LocaleContext = React.createContext();

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
//
// Gatsby has an API to automatically wrap every page: wrapPageElement. That was used
// before, but some repetition instead turned out to be better way to manage the mix
// of dynamic and static pages.
const Layout = ({ children, pageContext: { locale, alternates } }) => (
  <LocaleContext.Provider value={{ locale, alternates }}>
    <Navigation />
    <main role="main">{children}</main>
    <Footer />
  </LocaleContext.Provider>
);

export { Layout, LocaleContext };
