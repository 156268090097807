export type Locale = "fi" | "en";

export interface Loc {
  default?: boolean;
  path: string;
  locale: string;
  siteLanguage: string;
  ogLanguage: string;
  switchText: string;
  defaultTitle: string;
  defaultDescription: string;
}

const locs: Record<Locale, Loc> = {
  fi: {
    // Only one item MUST have the "default: true" key
    default: true,
    path: "",
    locale: `fi-FI`,
    siteLanguage: `fi`,
    ogLanguage: `fi_FI`,
    switchText: "Suomeksi",
    defaultTitle: `3 poles`,
    defaultDescription: `Mikko ja Tero hiihtävät etelänavalle ilman ulkopuolista tukea`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    switchText: "In English",
    defaultTitle: `3 poles`,
    defaultDescription: `Mikko and Tero skiing to the South Pole unsupported & unassisted`,
  },
};

export const locales: Locale[] = Object.keys(locs).map(k => k as Locale);
export const defaultLocale: Locale =
  (locales.find(v => locs[v].default) as Locale) ?? "fi";

export const getLocale = (l: Locale) => locs[l] ?? defaultLocale;
