import React from "react";

// Structured search data on one place, in json+ld format
// Remember to test the data with: https://search.google.com/structured-data/testing-tool/u/0/
const EventData = {
  "@context": "https://schema.org",
  "@type": "Event",
  name: "3poles.fi - South Pole 2022",
  startDate: "2022-11-15T12:00+00:00",
  endDate: "2023-01-31T12:00+00:00",
  eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
  eventStatus: "https://schema.org/EventScheduled",
  location: {
    "@type": "Place",
    name: "Antarctica",
    address: {
      "@type": "PostalAddress",
      addressCountry: "AQ",
    },
  },
  image: ["https://3poles.fi/ld-schema-event-image.jpg"],
  description:
    "Two experienced explorers Mikko Vermas & Tero Teelahti skiing to the South Pole unsupported & unassisted",
  performer: {
    "@type": "PerformingGroup",
    name: "Mikko Vermas & Tero Teelahti",
  },
  organizer: {
    "@type": "Organization",
    name: "3poles.fi",
    url: "https://3poles.fi",
  },
};

// This should be pasted on one page only, no benefit having it on multiple pages
const SEOSnippet = () => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(EventData) }}
  />
);

export { EventData, SEOSnippet };
