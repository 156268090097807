exports.components = {
  "component---src-pages-3-poles-challenge-js": () => import("./../../../src/pages/3-poles-challenge.js" /* webpackChunkName: "component---src-pages-3-poles-challenge-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-3-poles-challenge-js": () => import("./../../../src/pages/en/3-poles-challenge.js" /* webpackChunkName: "component---src-pages-en-3-poles-challenge-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-skiing-pace-calculator-js": () => import("./../../../src/pages/en/skiing-pace-calculator.js" /* webpackChunkName: "component---src-pages-en-skiing-pace-calculator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-prefix-markdown-remark-frontmatter-date-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__prefix}{MarkdownRemark.frontmatter__date}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-prefix-markdown-remark-frontmatter-date-js" */),
  "component---src-pages-skiing-pace-calculator-js": () => import("./../../../src/pages/skiing-pace-calculator.js" /* webpackChunkName: "component---src-pages-skiing-pace-calculator-js" */)
}

