module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-623a89deef/0/cache/gatsby-plugin-manifest-npm-5.12.3-f2709a1ddc-8f58e58a71.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"3poles-web","short_name":"web","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/home/runner/work/web/web/src/images/3poles-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"34711f8c02775c0a05bc22b268470d2c"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-67add84994/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
