import React from "react";
import { SEOSnippet } from "./structuredData";
import { StaticImage } from "gatsby-plugin-image";
import Link from "./localizedLink";
import TagWords from "./tagWords";
import useTranslations from "./useTranslations";

const Headline = () => {
  const { footerSlogan, footerMore } = useTranslations();
  // Simple way of localising the headline; we know that there is fixed amount of words
  const w = footerSlogan.split(" ");

  return (
    <TagWords
      width="300px"
      height="220px"
      words={[
        {
          style: { top: 0, left: 0, fontSize: "70px", fontWeight: "bolder" },
          txt: "2",
        },
        {
          style: { top: 43, left: 50, fontSize: "30px", fontWeight: "bold" },
          txt: w[0],
        },
        {
          style: { top: 78, left: 75, fontSize: "30px", fontWeight: "bold" },
          txt: w[1],
        },
        {
          style: { top: 110, left: 20, fontSize: "50px", fontWeight: "bolder" },
          txt: w[2],
        },
        {
          style: { bottom: 5, right: 5, fontWeight: "bold" },
          txt: <Link to="/3-poles-challenge">{footerMore}</Link>,
        },
      ]}
    />
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column is-narrow">
          <StaticImage
            src="../images/3poles-logo.png"
            width={360}
            height={360}
            alt="Logo"
          />
          <div
            className="has-text-info-dark"
            style={{ width: 360, paddingLeft: 50, marginTop: -70 }}
          >
            <Headline />
          </div>
        </div>
      </div>

      <SEOSnippet />
    </footer>
  );
};

export default Footer;
