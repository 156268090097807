import React from "react";

const TagWords = ({ words, width, height }) => (
  <div style={{ width: width, height: height, position: "relative" }}>
    {words.map((w, i) => (
      <span
        key={i}
        style={{
          position: "absolute",
          ...w.style,
        }}
      >
        {w.txt}
      </span>
    ))}
  </div>
);

export default TagWords;
