import React, { useState } from "react";
import LocalizedLink from "./localizedLink";
import LanSwitcher from "./lanSwitcher";
import useTranslations from "./useTranslations";
import { StaticImage } from "gatsby-plugin-image";

const Navigation = () => {
  const { navHome, nav3poles, navBlog, navContact, navPaceCalculator } =
    useTranslations();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <LocalizedLink
          to="/"
          aria-label={navHome}
          className="py-0 px-4"
          id="main-logo"
        >
          <StaticImage
            src="../images/3poles-logo.png"
            height={60}
            alt="3 poles logo"
          />
        </LocalizedLink>

        <a
          role="button"
          className={`navbar-burger burger ${open ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={open ? "true" : "false"}
          href="#nav"
          onClick={e => {
            e.preventDefault();
            toggle();
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${open ? "is-active" : ""}`}>
        <div className="navbar-start">
          <LocalizedLink to="/" className="navbar-item">
            {navHome}
          </LocalizedLink>

          <LocalizedLink to="/3-poles-challenge" className="navbar-item">
            {nav3poles}
          </LocalizedLink>

          <LocalizedLink to="/blog" className="navbar-item">
            {navBlog}
          </LocalizedLink>

          <LocalizedLink to="/skiing-pace-calculator" className="navbar-item">
            {navPaceCalculator}
          </LocalizedLink>

          <LocalizedLink to="/contact" className="navbar-item">
            {navContact}
          </LocalizedLink>
        </div>

        <LanSwitcher className="navbar-end" itemClassName="navbar-item" />
      </div>
    </nav>
  );
};

export default Navigation;
