import React from "react";
import { Link } from "gatsby";
import { LocaleContext } from "./layout";
import { locales, getLocale } from "../../config/i18n";

// Use the globally available context to choose which languages to show and hide
const LanSwitcher = ({ className, itemClassName }) => {
  const { locale, alternates } = React.useContext(LocaleContext);

  // Skip our current locale, no need to change to it
  const navneeded = locales.filter(k => k !== locale).map(getLocale);

  // Find the specific alternate path for this page, or fallback
  const lanPath = (loc, fallback) =>
    alternates?.find(v => v.hrefLang === loc.toLowerCase())?.path ?? fallback;

  return (
    <div className={className}>
      {navneeded.map(v => (
        <Link
          key={v.siteLanguage}
          to={lanPath(v.locale, "/" + v.path)}
          hrefLang={v.siteLanguage}
          className={itemClassName}
        >
          {v.switchText}
        </Link>
      ))}
    </div>
  );
};

export default LanSwitcher;
